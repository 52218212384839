body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: AgrandirRegular;
  src: url(./assets/fonts/Agrandir/Agrandir-Regular.otf);
}

@font-face {
  font-family: AgrandirBold;
  src: url(./assets/fonts/Agrandir/Agrandir-TextBold.otf);
}

@font-face {
  font-family: NeueRegular;
  src: url(./assets/fonts/NeueMachina/NeueMachina-Regular.otf);
}

@font-face {
  font-family: NeueBold;
  src: url(./assets/fonts/NeueMachina/NeueMachina-Ultrabold.otf);
}

@font-face {
  font-family: OpenSauceRegular;
  src: url(./assets/fonts/OpenSauce/OpenSauceOne-Regular.ttf);
}

@font-face {
  font-family: OpenSauceBold;
  src: url(./assets/fonts/OpenSauce/OpenSauceOne-Bold.ttf);
}

@font-face {
  font-family: AnyBodyRegular;
  src: url(./assets/fonts/Anybody/static/Anybody-Regular.ttf);
}

@font-face {
  font-family: AnyBodyBold;
  src: url(./assets/fonts/Anybody/static/Anybody-Bold.ttf);
}

@font-face {
  font-family: SpaceRegular;
  src: url(./assets/fonts/Space_Grotesk/static/SpaceGrotesk-Regular.ttf);
}

@font-face {
  font-family: SpaceBold;
  src: url(./assets/fonts/Space_Grotesk/static/SpaceGrotesk-Bold.ttf);
}

button {
  font-family: SpaceBold;
}

.MuiCard-root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
